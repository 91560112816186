/*
  @author Alistair, Lindsay, Jonah
  @description 2022 updated theme

  https://www.figma.com/file/yN7q1guf1Xc2loU0UX3X2b/library-(new)
  https://mui.com/customization/default-theme/#main-content
  DUPLICATED
*/

export const spacing = {
    0: 0,
    4: 4,
    8: 8,
    10: 10,
    12: 12,
    16: 16,
    20: 20,
    24: 24,
    28: 28,
    32: 32,
    36: 36,
    40: 40,
    44: 44,
    48: 48
  } as const
  
  export const defaultErrorMsg =
    'Oops! Something went wrong. If the problem persists, please contact your customer success manager.'
  
  const MUIzIndexes = {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500
  }
  
  export const zIndex = {
    minus: '-1',
    zero: 0,
    one: 1,
    abovePageLevel: 10,
    ...MUIzIndexes,
    pageSpinner: 1350
  }
  
  export const fontWeight300 = 300
  export const fontWeight400 = 400
  export const fontWeight600 = 600
  export const fontWeight800 = 800
  export const fontFamily = 'Open Sans'
  
  export const typography = {
    fontFamily: fontFamily,
    fontSize: 14,
    fontWeightLight: fontWeight300,
    fontWeightRegular: fontWeight400,
    fontWeightMedium: fontWeight600,
    fontWeightBold: fontWeight800,
    h1: {
      fontFamily: fontFamily,
      fontWeight: fontWeight300,
      fontSize: 95,
      lineHeight: '112px',
      letterSpacing: '-1.5px'
    },
    h2: {
      fontFamily: fontFamily,
      fontWeight: fontWeight300,
      fontSize: 59,
      lineHeight: '72px',
      letterSpacing: '-0.5px'
    },
    h3: {
      fontFamily: fontFamily,
      fontWeight: fontWeight400,
      fontSize: 48,
      lineHeight: '56px',
      letterSpacing: 0
    },
    h4: {
      fontFamily: fontFamily,
      fontWeight: fontWeight400,
      fontSize: 34,
      lineHeight: '36px',
      letterSpacing: 0
    },
    h5: {
      fontFamily: fontFamily,
      fontWeight: fontWeight400,
      fontSize: 24,
      lineHeight: '24px',
      letterSpacing: '0.18px'
    },
    h6: {
      fontFamily: fontFamily,
      fontWeight: fontWeight400,
      fontSize: 20,
      lineHeight: '24px',
      letterSpacing: '0.15px'
    },
    subtitle1: {
      fontFamily: fontFamily,
      fontWeight: fontWeight400,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.15px'
    },
    subtitle2: {
      fontFamily: fontFamily,
      fontWeight: fontWeight400,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.1px'
    },
    body1: {
      fontFamily: fontFamily,
      fontWeight: fontWeight400,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.5px'
    },
    body2: {
      fontFamily: fontFamily,
      fontWeight: fontWeight400,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.25px'
    },
    button: {
      fontFamily: fontFamily,
      fontWeight: fontWeight600,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '1.25px',
      textTransform: 'uppercase'
    },
    buttonDense: {
      fontFamily: fontFamily,
      fontWeight: fontWeight600,
      fontSize: 13,
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: '1.25px',
      textTransform: 'uppercase'
    },
    caption: {
      fontFamily: fontFamily,
      fontWeight: fontWeight400,
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '0.4px'
    },
    overline: {
      fontFamily: fontFamily,
      fontWeight: fontWeight400,
      fontSize: 10,
      lineHeight: '16px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase'
    }
  } as const
  
  export const breakpoints = {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1375,
      xl: 1600
    }
  }
  
  const overrides = {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          border: 'none'
        }
      }
    }
  }
  
  const rgba014 = 'rgba(146, 146, 146, 0.14)'
  const rgba012 = 'rgba(146, 146, 146, 0.12)'
  const rgba02 = 'rgba(146, 146, 146, 0.2)'
  
  export const shadows = [
    'none',
    `0px 1px 1px ${rgba014}, 0px 2px 1px ${rgba012}, 0px 1px 3px ${rgba02}`,
    `0px 2px 2px ${rgba014}, 0px 3px 1px ${rgba012}, 0px 1px 5px ${rgba02}`,
    `0px 3px 4px ${rgba014}, 0px 3px 3px ${rgba012}, 0px 1px 8px ${rgba02}`,
    `0px 4px 5px ${rgba014}, 0px 1px 10px ${rgba012}, 0px 2px 4px ${rgba02}`,
    `0px 6px 10px ${rgba014}, 0px 1px 18px ${rgba012}, 0px 3px 5px ${rgba02}`,
    `0px 8px 10px ${rgba014}, 0px 3px 14px ${rgba012}, 0px 5px 5px ${rgba02}`,
    `0px 9px 12px ${rgba014}, 0px 3px 16px ${rgba012}, 0px 5px 6px ${rgba02}`,
    `0px 12px 17px ${rgba014}, 0px 5px 22px ${rgba012}, 0px 7px 8px ${rgba02}`,
    `0px 16px 24px ${rgba014}, 0px 6px 30px ${rgba012}, 0px 8px 10px ${rgba02}`,
    `0px 24px 38px ${rgba014}, 0px 9px 46px ${rgba012}, 0px 11px 15px ${rgba02}`,
    `0px 6px 7px -4px ${rgba014}, 0px 11px 15px 1px ${rgba012}, 0px 4px 20px 3px ${rgba02}`,
    `0px 7px 8px -4px ${rgba014}, 0px 12px 17px 2px ${rgba012}, 0px 5px 22px 4px ${rgba02}`,
    `0px 7px 8px -4px ${rgba014}, 0px 13px 19px 2px ${rgba012}, 0px 5px 24px 4px ${rgba02}`,
    `0px 7px 9px -4px ${rgba014}, 0px 14px 21px 2px ${rgba012}, 0px 5px 26px 4px ${rgba02}`,
    `0px 8px 9px -5px ${rgba014}, 0px 15px 22px 2px ${rgba012}, 0px 6px 28px 5px ${rgba02}`,
    `0px 8px 10px -5px ${rgba014}, 0px 16px 24px 2px ${rgba012}, 0px 6px 30px 5px ${rgba02}`,
    `0px 8px 11px -5px ${rgba014}, 0px 17px 26px 2px ${rgba012}, 0px 6px 32px 5px ${rgba02}`,
    `0px 9px 11px -5px ${rgba014}, 0px 18px 28px 2px ${rgba012}, 0px 7px 34px 6px ${rgba02}`,
    `0px 9px 12px -6px ${rgba014}, 0px 19px 29px 2px ${rgba012}, 0px 7px 36px 6px ${rgba02}`,
    `0px 10px 13px -6px ${rgba014}, 0px 20px 31px 3px ${rgba012}, 0px 8px 38px 7px ${rgba02}`,
    `0px 10px 13px -6px ${rgba014}, 0px 21px 33px 3px ${rgba012}, 0px 8px 40px 7px ${rgba02}`,
    `0px 10px 14px -6px ${rgba014}, 0px 22px 35px 3px ${rgba012}, 0px 8px 42px 7px ${rgba02}`,
    `0px 11px 14px -7px ${rgba014}, 0px 23px 36px 3px ${rgba012}, 0px 9px 44px 8px ${rgba02}`,
    `0px 11px 15px -7px ${rgba014}, 0px 24px 38px 3px ${rgba012}, 0px 9px 46px 8px ${rgba02}`
  ]
  
  export const tooltips = {
    tooltip: {
      transform: 'translateY(-50%)',
      backgroundColor: '#FFF',
      color: '#2E2C2E',
      padding: spacing[8],
      borderRadius: '2px',
      boxShadow: shadows[6],
      zIndex: zIndex.one
    },
    arrowLeft: {
      display: 'block',
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translate(-50%, -50%) rotate(45deg)',
      width: '6px',
      height: '6px',
      background: '#FFF'
    },
    arrowBottom: {
      display: 'block',
      content: '""',
      position: 'absolute',
      left: '50px',
      top: '100%',
      transform: 'translate(-50%, -50%) rotate(45deg)',
      width: '12px',
      height: '12px',
      background: '#FFF'
    },
    arrowTop: {
      display: 'block',
      content: '""',
      position: 'absolute',
      left: '50px',
      top: 0,
      transform: 'translate(-50%, -50%) rotate(45deg)',
      width: '12px',
      height: '12px',
      background: '#FFF'
    }
  }
  
  export const palette = {
    common: {
      black: '#000',
      white: '#FFF'
    },
    primary: {
      light: '#32405F',
      main: '#061A35',
      dark: '#000010',
      contrastText: '#FFF'
    },
    secondary: {
      light: '#A7A8FF',
      main: '#6166FF',
      dark: '#0B3BCB',
      contrastText: '#FFF'
    },
    error: {
      light: '#E94948',
      main: '#B00020',
      dark: '#790000',
      contrastText: '#FFF'
    },
    warning: {
      light: '#FFC947',
      main: '#FF9800',
      dark: '#C66900',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      light: '#6EC6FF',
      main: '#2196F3',
      dark: '#0069C0',
      contrastText: '#FFF'
    },
    success: {
      light: '#80E27E',
      main: '#4CAF50',
      dark: '#087F23',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#FFF',
      default: '#F5F6F8'
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      secondaryHover: 'rgba(97, 102, 255, 0.04)',
      focus: 'rgba(0, 0, 0, 0.12)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      secondarySelected: 'rgba(97, 102, 255, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    }
  }
  
  export const additionalColors = {
    dataRed: '#EB004E',
    dataGreen: '#16A78A',
    loadingGray: '#E6E8EC',
    backgroundAlt: '#F9FAFC',
    navHeaderDark: '#1C2F47',
    tileBackground: '#132545',
    tileBackgroundAlt: '#1A2C45',
    tileBackgroundHover: '#192B4D',
    tileBackgroundAltHover: '#24354D',
    tileBackgroundLightGrey: '#32405F14',
    dark: {
      text: {
        primary: 'rgba(255, 255, 255, 0.87)',
        secondary: 'rgba(255, 255, 255, 0.54)',
        disabled: 'rgba(255, 255, 255, 0.38)'
      },
      divider: 'rgba(255, 255, 255, 0.12)'
    },
    serviceLine: {
      magenta: '#FF1A98',
      lilac: '#A7A8FF',
      yellow: '#FFCC33',
      blue: '#469EFF',
      grayDark: '#637791',
      grayLight: '#B9C3D0',
      pink: '#FFAFDB',
      orange: '#FF9852',
      purple: '#A455FF',
      lightestBlueGray: '#E3E7EB'
    },
    charts: {
      chartGreen1: '#E4FAF6',
      chartGreen2: '#C3F7EF',
      chartGreen3: '#B7F3E7',
      chartGreen4: '#98EEDD',
      chartGreen5: '#72E7D1',
      chartGreen6: '#55E2C7',
      chartGreen7: '#2ADBB8',
      chartGreen8: '#22C9A9',
      chartGreen9: '#20BC9E',
      chartLime: '#B0FD89'
    },
    miscellaneousColors: {
      14: '#38485D',
      15: '#1F3149'
    }
  }
  
  export const conductivTheme = {
    spacing: (val: string | number) => {
      if (Number(spacing[val]) && Number(val) >= 0) {
        return spacing[val]
      } else if (typeof val == 'string' && /[%]/.test(val)) {
        return val
      } else {
        return 16
      }
    },
    typography: typography,
    palette: palette,
    breakpoints: breakpoints,
    overrides: overrides,
    zIndex: MUIzIndexes,
    shape: {
      borderRadius: 0
    },
    shadows,
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          disableRipple: true
        },
        styleOverrides: {
          root: {
            borderRadius: 2
          }
        }
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover'
        }
      }
    }
  }
  
  const serviceLineColors = {
    2: additionalColors.serviceLine.magenta, // Clinical
    5: additionalColors.serviceLine.lilac, // Information Technology
    7: additionalColors.serviceLine.yellow, // Outsourced Clinical Services
    9: additionalColors.serviceLine.pink, // Staffing & Recruiting Services
    10: additionalColors.charts.chartLime, // Pharmacy
    11: additionalColors.serviceLine.blue, // Administrative / Finance / HR
    12: additionalColors.serviceLine.purple, // Animal Services
    13: additionalColors.serviceLine.grayLight, // Engineering / Biomed / Maintenance / Repair / Construction / Facilities Management
    14: additionalColors.serviceLine.orange, // Miscellaneous Purchased Service
    15: additionalColors.serviceLine.grayDark, // Miscellaneous Purchased Service
    16: additionalColors.charts.chartGreen7, // Non Purchased Service
    17: palette.secondary.main // Support Services
  }
  export const getServiceLineColor = (id: number) => (serviceLineColors[id] ? serviceLineColors[id] : '#E2E5E9')
  
  export const scrollbarStyle = {
    '& ::-webkit-scrollbar': {
      height: '10px'
    },
    ' & ::-webkit-scrollbar-track': {
      backgroundColor: 'divider'
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: 'primary.light',
      borderRadius: '4px'
    }
  }
  