import * as React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { LoginPayload } from "../../lib/api/type/loginPayload";
import { postLoginWithPassword } from "../../lib/api/authenticationAPI";
import logoHorizontal from "../../images/logoHorizontal.png";
import { CdButton } from "../master-components/cdButton";
import { CdTextField } from "../master-components";
import { palette, typography } from "../../utility/conductivTheme";
import { config } from "../../config";

interface ILoginPageProps {}

export const LoginPage: React.FunctionComponent<ILoginPageProps> = ({}) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState<string | null>(null);
  const login = async () => {
    setError(null);
    const payload: LoginPayload = { username, password };

    try {
      const response = await postLoginWithPassword(payload);
      Office.context.ui.messageParent(response);
      console.log("Message sent to Parent");
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Failed to log in. Please check your email and password and try again.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <img src={logoHorizontal} className="App-logo" alt="logo" />
      <Box
        sx={{
          width: "100%",
          padding: "24px",
          boxSizing: "border-box",
          backgroundColor: "#fff",
          boxShadow: 1,
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: palette.divider,
          marginTop: "24px",
        }}
      >
        <Typography
          sx={{
            color: "var(--primary-light, #32405F)",
            ...typography.h5,
            pb: "24px",
          }}
        >
          Login
        </Typography>

        {error && (
          <Typography sx={{ color: "var(--error, #f44336)", ...typography.caption, mb: "12px" }}>{error}</Typography>
        )}
        <CdTextField
          variant="filled"
          id="emailAddress"
          label="Email Address"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          sx={{ height: "69px", ...typography.caption }}
          error={error !== null}
        />
        <CdTextField
          variant="filled"
          id="password"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          sx={{ marginTop: 2, height: "69px", borderRadius: 0 }}
          error={error !== null}
        />
        <CdButton
          id="login-button"
          onClick={login}
          variant="contained"
          sx={{ marginTop: 2, width: "100%", backgroundColor: palette.secondary.main }}
        >
          <Typography sx={{ ...typography.button }}>Log in</Typography>
        </CdButton>
      </Box>
      <Box
        sx={{
          "& a": {
            color: palette.secondary.main,
            textDecoration: "none",
            "&:visited": {
              color: palette.secondary.main,
            },
            "&:hover": {
              textDecoration: "underline",
            },
          },
        }}
      >
        <Typography
          sx={{
            marginTop: 2,
            textAlign: "center",
            ...typography.body2,
            color: palette.secondary.main,
            cursor: "pointer",
          }}
          onClick={() => window.open(config.forgotPasswordUrl, "_blank")}
        >
          Reset Password?
        </Typography>
        <Typography
          sx={{
            marginTop: 1,
            textAlign: "center",
            ...typography.button,
            color: palette.secondary.main,
            cursor: "pointer",
          }}
          onClick={() => window.open(config.registerUrl, "_blank")}
        >
          REGISTER
        </Typography>
      </Box>
    </Box>
  );
};
