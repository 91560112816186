/*
  @author Jonah Grinage
  @description CdButton master component
*/
import React, { ReactNode } from "react";
import { Button, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { palette } from "../../utility/conductivTheme";

export type CdButtonType = {
  onClick?: (e: React.MouseEvent) => void;
  id: string;
  children?: ReactNode;
  icon?: ReactNode;
  showIcon?: boolean;
  disabled?: boolean;
  variant?: "text" | "contained" | "outlined";
  size?: "small" | "medium" | "large";
  sx?: SxProps;
  href?: string;
  component?: React.ReactElement | string;
  autoFocus?: boolean;
  submit?: boolean;
  disableHover?: boolean;
};

export const CdButton = ({
  onClick,
  id,
  children,
  icon,
  autoFocus = false,
  showIcon = true,
  disabled = false,
  variant = "contained",
  size = "medium",
  sx,
  href,
  component,
  submit = false,
  disableHover = false,
}: CdButtonType) => {
  return (
    <Button
      type={submit ? "submit" : undefined}
      autoFocus={autoFocus}
      //component={component}
      size={size}
      color={variant === "outlined" ? "primary" : "secondary"}
      id={id}
      variant={variant}
      disabled={disabled}
      startIcon={icon ? (showIcon ? icon : null) : null}
      onClick={onClick}
      sx={{
        "& svg": {
          color: variant === "outlined" && disabled === false ? palette.secondary.main : "",
        },
        ...sx,
        "&:hover": {
          backgroundColor: disableHover ? "transparent" : palette.secondary.main,
        },
      }}
      href={href}
      //target={href ? '_blank' : null}
      //rel={href ? 'noopener' : null}
    >
      <Typography
        sx={{ fontSize: "inherit" }}
        variant="button"
        color={variant === "text" && icon && disabled === false ? palette.primary.main : ""}
      >
        {children}
      </Typography>
    </Button>
  );
};
