/*
  @author Alistair Hill, Lindsay Gaudinier
  @description CdTextField master component
*/

import React, {ReactNode} from 'react'
import {SxProps} from '@mui/system'
import {TextField} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

import {spacing, typography, palette} from '../../utility/conductivTheme'
import {CdIconButton} from './cdIconButton'

type CdTextFieldType = {
  id: string
  variant: 'filled' | 'standard'
  inputProps?: any
  InputProps?: any
  fullWidth?: boolean
  disabled?: boolean
  size?: 'medium' | 'small' | string
  params?: any
  sx?: SxProps
  label?: string
  helperText?: string | ReactNode
  error?: boolean
  onChange?: (value: any) => void
  value?: any
  multiline?: boolean
  defaultValue?: any
  type?: any
  placeholder?: string
  required?: boolean
  rows?: number
  onFocus?: () => void
  onBlur?: () => void
  onKeyDown?: (value: any) => void
  select?: boolean
  children?: React.ReactNode
  autoFocus?: boolean
  width?: string | number
  height?: string | number
  autoComplete?: string
  onCancelButtonClick?: () => void
}

export const CdTextField = ({
  type,
  id,
  variant = 'filled',
  inputProps,
  InputProps,
  size,
  params,
  sx,
  label,
  helperText,
  onChange,
  value,
  autoFocus = false,
  multiline = false,
  error = false,
  fullWidth = true,
  disabled = false,
  defaultValue,
  placeholder,
  required,
  rows,
  onFocus,
  onBlur,
  onKeyDown,
  select,
  children,
  width = '100%',
  height = '100%',
  autoComplete = 'none',
  onCancelButtonClick
}: CdTextFieldType) => (
  <TextField
    autoComplete={autoComplete}
    width={width}
    defaultValue={defaultValue}
    type={type}
    multiline={multiline}
    onChange={onChange}
    value={value}
    error={error}
    helperText={helperText}
    label={label}
    {...params}
    id={id}
    size={size}
    variant={variant}
    disabled={disabled}
    placeholder={placeholder}
    required={required}
    rows={rows}
    onFocus={onFocus}
    onBlur={onBlur}
    onKeyDown={onKeyDown}
    select={select}
    sx={{
      '& div.MuiInputBase-root': {
        p: `${spacing[0]}px`,
        height: height
      },
      '& .MuiInputBase-input.MuiFilledInput-input, & .MuiInputBase-multiline': {
        p: `${spacing[24]}px ${spacing[12]}px ${spacing[8]}px`
      },
      '& .MuiFilledInput-root::after': {
          borderBottomColor: palette.secondary.main
        },
      '& .MuiInputLabel-formControl.Mui-focused': {
        color: 'text.secondary',
        ...typography.caption
      },
      '& .MuiInputLabel-formControl': {
        ...typography.caption
      },
      '& div': {
        color: 'text.primary',
        typography: 'body2'
      },
      ...sx
    }}
    fullWidth={fullWidth}
    inputProps={inputProps}
    InputProps={{
      ...InputProps,
      endAdornment: onCancelButtonClick && (
        <CdIconButton
          id={`${id}-cd-textfield-icon-button`}
          ariaLabel='cancel icon button'
          color='primary'
          onClick={onCancelButtonClick}
        >
          <ClearIcon />
        </CdIconButton>
      )
    }}
  >
    {select ? children : null}
  </TextField>
)
