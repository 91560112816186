import { config } from "../config";

export const clearEmptyValues = <T>(query: Record<string, T> | { [key: string]: T }) =>
  Object.keys(query).reduce((prev, curr) => {
    if (curr && query[curr]) {
      return {
        ...prev,
        [curr]: query[curr],
      };
    }

    return prev as any;
  }, {} as Record<string, string>);

type Config = typeof config;

const pathQueryHelper =
  (configUrl?: Config[keyof Config]) => (path: string, organizationIdOrQuery?: number | Record<string, string>) => {
    if (typeof organizationIdOrQuery === "number") {
      return `${configUrl}${path}?organizationId=${organizationIdOrQuery}`;
    }

    if (organizationIdOrQuery) {
      return `${configUrl}${path}?${new URLSearchParams(clearEmptyValues(organizationIdOrQuery)).toString()}`;
    }

    return `${configUrl}${path}`;
  };

export const getAuthServiceUrl = pathQueryHelper(config.authServiceUrl)
