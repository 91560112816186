/*
  @author Alistair Hill
  @description CdIconButton master component. Icon reference: https://mui.com/material-ui/material-icons
*/
import React, {ReactNode, Children} from 'react'
import {SxProps} from '@mui/system'
import {IconButton} from '@mui/material'

type CdIconButtonType = {
  onClick?: any
  id: string
  children?: ReactNode
  ariaLabel: string
  edge?: 'end' | 'start' | false
  component?: string
  disabled?: boolean
  color?: 'primary' | 'secondary'
  size?: 'small' | 'medium' | 'large'
  sx?: SxProps
  disableRipple?: boolean
  checked?: boolean
}

export const CdIconButton = ({
  onClick,
  id,
  children,
  ariaLabel,
  edge,
  disableRipple,
  component = 'span',
  disabled = false,
  color = 'secondary',
  size = 'medium',
  sx,
  checked = false
}: CdIconButtonType) => {
  const icon = Children.map(children, (child: any, index: number) => {
    return (
      <>
        {React.cloneElement(child, {
          index: index,
          fontSize: 'inherit'
        })}
      </>
    )
  })

  return children ? (
    <IconButton
      disableRipple={disableRipple}
      sx={{
        ...sx,
        padding: size === 'large' ? '8px' : size === 'medium' ? '3.67px' : '3.33px',
        backgroundColor: checked ? (color === 'primary' ? 'action.selected' : 'action.secondarySelected') : ''
      }}
      edge={edge}
      id={id}
      size={size}
      color={color}
      aria-label={ariaLabel}
      //component={component}
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
    </IconButton>
  ) : null
}
