/*
  @author Austin Kang
  @description lib Authentication API
*/

import { getAuthServiceUrl } from "../../utility/utility";
import { apiFactory } from "../api/api";
import { LoginPayload } from "./type/loginPayload";

const { post } = apiFactory(getAuthServiceUrl("api/login/"));

const postLoginWithPassword = async (payload: LoginPayload) => {
  try {
        const response = await post({}, payload);
        const data = await response.json();
        console.log("Login Response:", data); // Log the parsed response data
        return JSON.stringify({ jwtToken: data.token, refreshToken: data.refreshToken });
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    }
};

export { postLoginWithPassword };
