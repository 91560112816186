/* global process */

export const config = {
  authServiceUrl: process.env.REACT_APP_AUTH_SERVICE_URL,
  graphGatewayUrl: process.env.REACT_APP_GRAPH_GATEWAY_URL,
  contractExplorerUrl: process.env.REACT_APP_CONTRACT_EXPLORER_URL,
  forgotPasswordUrl: process.env.REACT_APP_FORGOT_PASSWORD_URL,
  registerUrl: process.env.REACT_APP_REGISTER_URL,
  jwtToken: process.env.REACT_APP_JWT_NAME,
  refreshToken: process.env.REACT_APP_REFRESH_TOKEN_NAME,
  domainUrl: process.env.REACT_APP_DOMAIN_URL,
};
